.youtube-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.youtube-player {
    width: 100%;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.description {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
    text-align: center;
}
